// export let ROOT_URL;
// if (process.env.REACT_APP_ENV === "production") {
//   ROOT_URL = "https://api.uvihealth.in/dft";
// } else if (process.env.REACT_APP_ENV === "staging") {
//   ROOT_URL = "https://apistage.uvihealth.in/dft";
// } else {
//   ROOT_URL = "http://localhost:3000/dft";
// }

export let ROOT_URL = 'https://apistage.uvihealth.in/dft';
